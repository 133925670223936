import React from 'react';
import get from 'lodash.get'
import set from 'lodash.set'
import uuid from 'uuid/v4';
import isEqual from 'lodash.isequal';

import './sheet.scss';
import Line from '../line';

export class Sheet extends React.Component {
  static defaultProps = {
    sheet: {},
    onChange: () => {}
  }

  constructor(props) {
    super(props);
    this.state = {
      sheet: props.sheet,
    };
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ sheet: this.props.sheet })
    }
  }
  addLine = () => {
    const { onChange } = this.props;
    const { sheet } = this.state;
    sheet.lines.push({
      id: uuid(),
      phrases: [{
        id: uuid(),
      }]
    })
    this.setState({ sheet }, () => onChange(sheet));
  }

  deleteLine = (id) => () => {
    const { onChange } = this.props;
    const { sheet } = this.state;
    const lineIndex = sheet.lines.findIndex(line => line.id === id);
    if (lineIndex > -1) {
      sheet.lines.splice(lineIndex, 1);
      this.setState({ sheet }, () => onChange(sheet));
    }
  }

  onChange = (value) => {
    const { onChange } = this.props;
    const { sheet } = this.state;

    set(sheet, `lines[${sheet.lines.findIndex((line) => value.id === line.id)}]`, value);
    this.setState({ sheet }, () => onChange(sheet));
  }

  renderLines() {
    const { sheet } = this.state;
    return get(sheet, 'lines', []).map((line) =>
      <div className='row center center-squished' key={line.id}>
        <div className="delete-line-group row center-squished noprint">
          <img src={require('../../assets/delete.svg')} alt={`delete_${line.id}`} onClick={this.deleteLine(line.id)}/>
          <span>delete line</span>
        </div>
        <Line line={line} onChange={this.onChange}></Line>
      </div>
    );
  }

  render() {
    return (
      <div className='sheet-container column left'>
        {this.renderLines()}
        <div className='row center center-spread'>
          <img className="add-line-btn noprint" src={require('../../assets/add.svg')} alt='add_line' onClick={this.addLine}/>
          <span>add line</span>
        </div>
      </div>
    );
  }
}

export default Sheet;