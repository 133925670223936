import React from 'react';
import './phrase.scss';
import get from 'lodash.get';
import autosizeInput from 'autosize-input';
import uuid from 'uuid/v4';

import Chord from '../chord';

export class Phrase extends React.Component {
  static defaultProps = {
    phrase: {},
    onChange: () => {},
  }
  constructor(props) {
    super(props);
    this.state = {
      phrase: props.phrase,
    }
    this.uuid = uuid();
  }
  componentDidMount() {
    autosizeInput(document.getElementById(this.uuid));
  }
  onChange = (key) => (value) => {
    const { onChange } = this.props;
    this.setState(prevState => ({
      phrase: {
        ...prevState.phrase,
        [key]: value,
      }
    }), () => onChange(this.state.phrase));
  }
  onLyricChange = (event) => this.onChange('lyric')(get(event, 'target.value'))
  focusInput = () => {
    document.getElementById(this.uuid).focus();
  }
  render() {
    const { phrase } = this.state;
    return (
        <div className={`phrase-container column left`} onClick={this.focusInput}>
            <Chord chord={get(phrase, 'chord')} onChange={this.onChange('chord')} />
            <input
              id={this.uuid}
              className='lyric-input'
              type="text"
              placeholder="lyrics"
              defaultValue={get(phrase, 'lyric', undefined)}
              onChange={this.onLyricChange}
            />
        </div>
    );
  }
}

export default Phrase;