import React from 'react';
import './line.scss';
import Phrase from '../phrase';
import get from 'lodash.get';
import set from 'lodash.set';
import uuid from 'uuid/v4';

export class Line extends React.Component {
  static defaultProps = {
    line: {},
    onChange: () => {}
  }

  constructor(props) {
    super(props);
    this.state = {
      line: props.line,
    };
  }
  addPhrase = () => {
    const { onChange } = this.props;
    const { line } = this.state;

    line.phrases.push({
      id: uuid(),
      lyric: '',
      chord: {}
    })
    this.setState({ line }, () => onChange(line));
  }

  onChange = (value) => {
    const { onChange } = this.props;
    const { line } = this.state;

    set(line, `phrases[${line.phrases.findIndex((phrase) => value.id === phrase.id)}]`, value);
    this.setState({ line }, () => onChange(line));
  }

  deletePhrase = (id) => () => {
    const { onChange } = this.props;
    const { line } = this.state;
    const phraseIndex = line.phrases.findIndex(phrase => phrase.id === id);
    if (phraseIndex > -1) {
      line.phrases.splice(phraseIndex, 1);
      this.setState({ line }, () => onChange(line));
    }
  }

  renderPhrases() {
    const { line } = this.props;
    return get(line, 'phrases', [])
      .map((phrase) =>
        <div key={phrase.id} className='delete-phrase-group column center center-squished'>
          <img className="delete-phrase-btn noprint" src={require('../../assets/delete.svg')} alt={`delete_${line.id}`} onClick={this.deletePhrase(phrase.id)}/>
          <span>delete chord+lyric</span>
          <Phrase onChange={this.onChange} phrase={phrase} />
        </div>
      )
  }

  render() {
    return (
      <div className='line-container row center wrap'>
          {this.renderPhrases()}
          <img className="add-phrase-btn noprint" src={require('../../assets/add.svg')} alt='add_phrase' onClick={this.addPhrase}/>
          <span>add chord+lyric</span>
      </div>
    );
  }
}

export default Line;