import React from 'react';
import Sheet from './components/sheet';
import get from 'lodash.get';
import uuid from 'uuid/v4';
import autosizeInput from 'autosize-input';

import './App.scss';

class App extends React.Component {
  constructor() {
    super();
    const id = uuid();
    this.state = {
      selectedSheetPage: 0,
      song: {
        title: 'New Song Title',
        sheets: [{
          id,
          lines: []
        }]
      }
    };
  }
  componentDidMount() {
    autosizeInput(document.querySelector('.title-input'));
  }
  openSong = () => {
    try {
      var input = document.getElementById("file");
      var fReader = new FileReader();
      fReader.readAsDataURL(input.files[0]);
      fReader.onloadend = (event) => {
        const base64Encoding = event.target.result;
        this.setState({ song: JSON.parse(atob(base64Encoding.substring(29))) });
      }
    } catch (error) {
      alert('There was a problem opening your file:', error);
    }
  }

  saveSong= () => {
    const { song } = this.state;
    if (song && Object.keys(song).length > 0) {
      var a = document.createElement("a");
      var file = new Blob([JSON.stringify(song)], {type: 'application/json'});
      a.href = URL.createObjectURL(file);
      a.download = song.title;
      a.click();
    }
  }

  onChange = (sheet) => {
    const newSong = this.state.song;
    const sheetIndex = newSong.sheets.findIndex(oldSheet => oldSheet.id === sheet.id);
    if (sheetIndex > -1) {
      newSong.sheets[sheetIndex] = sheet;
      this.setState({ song: newSong });
    }
  }
  selectSheet = (pageNumber) => () => {
    this.setState({ selectedSheetPage: pageNumber });
  }
  addSheet = () => {
    const newSong = this.state.song;
    const newId = uuid();
    newSong.sheets.push({
      id: newId,
      lines: []
    });
    this.setState({
      song: newSong,
      selectedSheetPage: newSong.sheets.length - 1,
    })
  }
  clickFileInput() {
    document.getElementById('file').click();
  }
  updateTitle = (event) => {
    const newSong = this.state.song;
    newSong.title = get(event, 'target.value', this.state.song.title);
    this.setState({ song: newSong });
  }
  renderSheetSelector() {
    const { song, selectedSheetPage } = this.state;
    return get(song, 'sheets', []).map((sheet, index) =>
        <span className='sheet-selector row center center-squished' style={selectedSheetPage === index ? { backgroundColor: 'teal' } : {}} onClick={this.selectSheet(index)}>{index + 1}</span>
    );
  }
  renderSheets() {
    const { song, selectedSheetPage } = this.state;
    return get(song, 'sheets', []).map((sheet, index) =>
      <div style={index !== selectedSheetPage ? { display: 'none' } : {}} key={sheet.id}>
        <Sheet key={sheet.id} sheet={sheet} onChange={this.onChange}></Sheet>
      </div>
    );
  }
  render() {
    const { song } = this.state;
    return (
      <div className='app-container column top center'>
        <div className='io-row row center center-spread'>
          <input style={{ display: 'none' }} type="file" id="file" onChange={this.openSong} accept='.json' />
          <img className='open-btn noprint' src={require('./assets/open.svg')} alt='open_song' onClick={this.clickFileInput}/>
          <input className='title-input' type='text' onChange={this.updateTitle} defaultValue={song.title} placeholder='New Song Title' />
          <img className='save-btn noprint' src={require('./assets/save.svg')} alt='save_song' onClick={this.saveSong}/>
        </div>
        <div className='sheet-selectors row center center-spaced noprint'>
          {this.renderSheetSelector()}
          <img className='add-sheet-btn' height='50' width='50' src={require('./assets/add.svg')} alt='add_sheet' onClick={this.addSheet}/>
          <span>add page</span>
        </div>
        {song && this.renderSheets()}
      </div>
    );
  }
}

export default App;
