import React from 'react';
import get from 'lodash.get';
import uuid from 'uuid/v4';

import './chord.scss';

const rootNotes = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G'
}
const steps = {
  sharp: '#',
  flat: '♭',
  natural: '♮'
}
const intervals = {
  major: 'Δ',
  minor: '−',
  diminished: '°',
  augmented: '+',
  dominant: 'ø'
}

const addedTones = [2, 3, 4, 5, 6, 7, 8 , 9, 10, 11, 12];

export class Chord extends React.Component {
  static defaultProps = {
    chord: {},
    onChange: () => {}
  }
  constructor(props) {
    super(props);
    this.state = {
      chord: props.chord,
    };
    this.uuid = uuid();
  }
  chordToString(chord) {
    const { rootNote, step, interval, addedTone } = chord;
    const rootNoteMapping = rootNote ? rootNotes[rootNote] : '';
    const stepMapping = step && step !== 'natural' ? steps[step] : '';
    const intervalMapping = interval && interval !== 'major' ? intervals[interval] : '';
    const addedToneMapping = addedTone ? <sup>{`add(${addedTones[addedTone]})`}</sup> : '';
    if (rootNoteMapping === '' && stepMapping === '' && intervalMapping === '') {
      return (<img className='fretboard' src={require('../../assets/fretboard.svg')} alt={`fretboard_${this.uuid}`}/>);
    }
    return (<span>{`${rootNoteMapping}${stepMapping}${intervalMapping}`}{addedToneMapping}</span>);
  }

  onChange = (key) => (event) => {
    const { onChange } = this.props;
    const value = get(event, 'target.value');
    this.setState(prevState => ({
      chord: {
        ...prevState.chord,
        [key]: value,
      }
    }), () => onChange(this.state.chord));
  }
  onSelect = (key, value) => () => {
    let toShowNext;
    switch(key) {
      case 'rootNote':
        toShowNext = 'showStepSelector';
        break;
      case 'step':
        toShowNext = 'showIntervalSelector';
        break;
      case 'interval':
        toShowNext = 'showAddedTonesSelector';
        break;
      default:
        break;
    }
    const { onChange } = this.props;
    this.setState(prevState => ({
      chord: {
        ...prevState.chord,
        [key]: prevState.chord[key] === value ? undefined : value,
      },
      [toShowNext]: true
    }), () => onChange(this.state.chord));
  }
  renderAddedTones = () => {
    const { chord } = this.state;
    return Object.keys(addedTones).map(addedTone =>
      <span
        className={`row center center-squished ${chord.addedTone === addedTone && 'selected'}`}
        key={addedTone}
        onClick={this.onSelect('addedTone', addedTone)}>
          {addedTones[addedTone]}
      </span>
    );
  }
  renderIntervals = () => {
    const { chord } = this.state;
    return Object.keys(intervals).map(interval =>
      <span
        className={`row center center-squished ${chord.interval === interval && 'selected'}`}
        key={interval}
        onClick={this.onSelect('interval', interval)}>
          {intervals[interval]}
      </span>
    );
  }
  renderSteps = () => {
    const { chord } = this.state;
    return Object.keys(steps).map(step =>
      <span
        className={`row center center-squished ${chord.step === step && 'selected'}`}
        key={step}
        onClick={this.onSelect('step', step)}>
          {steps[step]}
      </span>
    );
  }
  renderRootNotes = () => {
    const { chord } = this.state;
    return Object.keys(rootNotes).map(rootNote =>
      <span
        className={`row center center-squished ${chord.rootNote === rootNote && 'selected'}`}
        key={rootNote}
        onClick={this.onSelect('rootNote', rootNote)}>
          {rootNotes[rootNote]}
      </span>
    );
  }
  toggleRootNoteSelector = () => {
    this.setState(prevState => ({
      showSelectors: !prevState.showSelectors,
    }));
  }
  focusSelector = () => {
    const { showSelectors } = this.state;
    const selectorContainer = document.getElementById(this.uuid);
    const centeredChord = document.querySelector('.chord');

    const topOffset = selectorContainer && window.getComputedStyle(selectorContainer).top;
    const leftOffset = selectorContainer && window.getComputedStyle(selectorContainer).left;
    const chordWidth = centeredChord && window.getComputedStyle(centeredChord).width;

    return showSelectors ? {
      transform: `translate(calc(100vw/2 - ${chordWidth}/2 - ${leftOffset}), calc(100vh/2 - ${chordWidth}/2 - ${topOffset}))`,
      zIndex: 5
    } : {};
  }
  render() {
    const { chord, showRootNoteSelector, showStepSelector, showSelectors, showIntervalSelector, showAddedTonesSelector } = this.state;
    return (
      <div className='chord-container'>
        <div id={this.uuid} className={`selector-container row ${showSelectors ? 'selecting': ''}`} style={this.focusSelector()}>
          <div className={`added-tones-selector ${showSelectors && showAddedTonesSelector ? 'show': ''}`}>
            {this.renderAddedTones()}
          </div>
          <div className={`interval-selector ${showSelectors && showIntervalSelector ? 'show': ''}`}>
            {this.renderIntervals()}
          </div>
          <div className={`step-selector ${showSelectors && showStepSelector ? 'show': ''}`}>
            {this.renderSteps()}
          </div>
          <div className={`root-note-selector ${showSelectors || showRootNoteSelector ? 'show' : ''}`}>
            {this.renderRootNotes()}
          </div>
          <div className={`chord row center center-squished ${showSelectors && 'show'}`} onClick={this.toggleRootNoteSelector}>
            {this.chordToString(chord)}
          </div>
        </div>
      </div>
    );
  }
}

export default Chord;